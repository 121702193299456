import Swal from 'sweetalert2';
import { payItemAction, rebalanceItemAction } from '../Action/action';

const commonPopupStyle = {
  customClass: {
    popup: 'payment-popup'
  },
  showCancelButton: true,
  cancelButtonText: 'Annulla',
  cancelButtonColor: '#6c757d',
  reverseButtons: true
};

const getPaymentLabel = (payment) => {
  switch(payment.type) {
    case 'BEFORE_TARGET':
      return 'Da Revolut fornitori a IBAN fornitore';
    case 'AFTER_TARGET':
      return 'Da Revolut clienti a IBAN fornitore';
    case 'REVENUE':
      return 'Da conto clienti a IBAN Collecto SELLA';
    case 'COLLECTO_REBALANCE':
      return 'Da conto clienti a Revolut fornitori';
    default:
      return payment.type;
  }
};

export const showPaymentDetailsPopup = async (itemName, payments, itemId) => {
  const paymentsHtml = payments.map((payment, index) => `
    <div class="payment-item ${index !== 0 ? 'border-top mt-3 pt-3' : ''}">
      <p class="mb-2"><strong>${getPaymentLabel(payment)}</strong></p>
      <p class="mb-0" style="color: #28a745; font-size: 1.1em;">€ ${payment.amount.toLocaleString('it-IT', { minimumFractionDigits: 2 })}</p>
    </div>
  `).join('');

  const result = await Swal.fire({
    ...commonPopupStyle,
    title: 'Riepilogo Pagamento',
    html: `
      <div class="text-left">
        <p class="mb-4"><strong>Item:</strong> ${itemName}</p>
        <div class="payment-list" style="max-height: 300px; overflow-y: auto; padding-right: 10px;">
          ${paymentsHtml}
        </div>
      </div>
    `,
    confirmButtonText: 'Conferma Pagamento',
    confirmButtonColor: '#28a745'
  });

  if (result.isConfirmed) {
    try {
      const response = await payItemAction(itemId);
      if (response.success) {
        await Swal.fire({
          title: "Successo",
          text: "Pagamento effettuato con successo",
          icon: "success"
        });
        return { success: true };
      } else {
        await Swal.fire({
          title: "Errore",
          text: response.msg || "Errore durante il pagamento",
          icon: "error"
        });
        return { success: false };
      }
    } catch (error) {
      await Swal.fire({
        title: "Errore",
        text: error.response?.data?.msg || "Errore durante il pagamento",
        icon: "error"
      });
      return { success: false };
    }
  }
  return { success: false };
};

export const showRebalanceDetailsPopup = async (itemName, payments, itemId) => {
  const paymentsHtml = payments.map((payment, index) => `
    <div class="payment-item ${index !== 0 ? 'border-top mt-3 pt-3' : ''}">
      <p class="mb-2"><strong>${getPaymentLabel(payment)}</strong></p>
      <p class="mb-0" style="color: #28a745; font-size: 1.1em;">€ ${payment.amount.toLocaleString('it-IT', { minimumFractionDigits: 2 })}</p>
    </div>
  `).join('');

  const result = await Swal.fire({
    ...commonPopupStyle,
    title: 'Riepilogo Ribilanciamento',
    html: `
      <div class="text-left">
        <p class="mb-4"><strong>Item:</strong> ${itemName}</p>
        <div class="payment-list" style="max-height: 300px; overflow-y: auto; padding-right: 10px;">
          ${paymentsHtml}
        </div>
      </div>
    `,
    confirmButtonText: 'Conferma Ribilanciamento',
    confirmButtonColor: '#ffc107'
  });

  if (result.isConfirmed) {
    try {
      const response = await rebalanceItemAction(itemId);
      if (response.success) {
        await Swal.fire({
          title: "Successo",
          text: "Ribilanciamento effettuato con successo",
          icon: "success"
        });
        return { success: true };
      } else {
        await Swal.fire({
          title: "Errore",
          text: response.msg || "Errore durante il ribilanciamento",
          icon: "error"
        });
        return { success: false };
      }
    } catch (error) {
      await Swal.fire({
        title: "Errore",
        text: error.response?.data?.msg || "Errore durante il ribilanciamento",
        icon: "error"
      });
      return { success: false };
    }
  }
  return { success: false };
}; 