import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import config from "../coreFIles/config";
import { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { userdeletetAction, updatekycapprovalAction, rejectkycapprovalAction, getUsersLiquidity, setUsersLiquidity, getUsersV2ListAction, getAllUsers } from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import { Button, createTheme, MenuItem, ThemeProvider } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";

const UsersV2 = () => {
  //Liquidity Modal
  const [item, setItem] = useState({});
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saving, setsaving] = useState(false);
  const [lqiAmt, setLqiAmt] = useState(0);
  const [isTransfer, setIsTransfer] = useState(false);
  const [validatioError, setvalidatioError] = useState({});
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const prevColumnFilters = useRef(columnFilters);

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark", //let's use the same dark/light mode as the global theme
        },
      }),
    []
  );
  useEffect(() => {
    const fetchData = async () => {
      if (!data) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        let filtersToUse = JSON.stringify(columnFilters ?? []);
        /* const urlParams = new URLSearchParams(window.location.search);
        const urlPage = urlParams.get("page");
        const urlLimit = urlParams.get("limit");
        const urlFilters = urlParams.get("filters");

        console.log({ urlPage, urlLimit, urlFilters, data: data.length, pageIndex, pageSize, columnFilters: columnFilters.length });
        // Check if it's the first page load and there are filters in the URL
        if (data.length === 0 && (urlPage !== null || urlLimit !== null || urlFilters !== null) && pageIndex === 0 && pageSize === 10) {
          if (urlFilters) {
            filtersToUse = urlFilters;
            setColumnFilters(JSON.parse(urlFilters));
          }
          if (urlPage) {
            pageIndex = parseInt(urlPage);
            setPagination((prev) => ({ ...prev, pageIndex, pageSize }));
          }
        }
        const url = `${config.baseUrl}V2users?page=${pageIndex}&limit=${pageSize}${filtersToUse !== "[]" ? `&filters=${encodeURIComponent(filtersToUse)}` : ""}`;
        // Update URL without refreshing the page
        window.history.pushState(null, "", url); */

        // Use the filters for the API call
        const response = await getUsersV2ListAction(pagination.pageIndex, pagination.pageSize, filtersToUse);
        setData(response.data.users);
        setRowCount(response.data.totalItems);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters, //re-fetch when column filters change
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
  ]);

  useEffect(() => {
    setCsvData([]);
    /* if (JSON.stringify(prevColumnFilters.current) !== JSON.stringify(columnFilters) && prevColumnFilters.current.length > 0) {
      console.log(columnFilters, prevColumnFilters.current);
      setPagination({
        pageIndex: 0,
        pageSize: 10,
      });
    }
    prevColumnFilters.current = columnFilters; */
  }, [columnFilters]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "User ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "profile_pic",
        header: "Profile Picture",
        enableSorting: false,
        enableColumnFilter: false,
        grow: false,
        size: 50,
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <>
              {item.signup_by === "GMAIL" || item.signup_by === "FACEBOOK" || item.signup_by === "APPLE" ? (
                parseInt(item.profile_change_stt) === 1 ? (
                  item.profile_pic === 0 || item.profile_pic === null || item.profile_pic === "" ? (
                    <img
                      src="./images/avatar-2.png"
                      className="bg-primary-light"
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                      }}
                    />
                  ) : (
                    <a target="_blank" href={`${config.imageUrl}${item.profile_pic}`} rel="noopener noreferrer">
                      <img
                        src={`${config.imageUrl}${item.profile_pic}`}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50px",
                        }}
                        alt=""
                      />
                    </a>
                  )
                ) : item.profile_pic === 0 || item.profile_pic === null || item.profile_pic === "" ? (
                  <img
                    src="./images/avatar-2.png"
                    className="bg-primary-light"
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50px",
                    }}
                  />
                ) : (
                  <a target="_blank" href={item.profile_pic} rel="noopener noreferrer">
                    <img
                      src={item.profile_pic}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                      }}
                      alt=""
                    />
                  </a>
                )
              ) : item.profile_pic === 0 || item.profile_pic === null || item.profile_pic === "" ? (
                <img
                  src="./images/avatar-2.png"
                  className="bg-primary-light"
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <a target="_blank" href={`${config.imageUrl}${item.profile_pic}`} rel="noopener noreferrer">
                  <img
                    src={`${config.imageUrl}${item.profile_pic}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50px",
                    }}
                    alt=""
                  />
                </a>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        Cell: ({ row }) => {
          const user = row.original;
          if (user.created_by_gift && !user.email) {
            return <span style={{ color: '#FFA500' }}>Gift User</span>;
          }
          return user.name || '-';
        }
      },
      {
        accessorKey: "cognome",
        header: "Last Name",
        enableSorting: false,
        Cell: ({ row }) => {
          const user = row.original;
          if (user.created_by_gift && !user.email) {
            return <span style={{ color: '#FFA500' }}>Gift User</span>;
          }
          return user.cognome || '-';
        }
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        Cell: ({ row }) => {
          const user = row.original;
          if (user.created_by_gift && !user.email) {
            return <span style={{ color: '#FFA500' }}>Gift User</span>;
          }
          return user.email || '-';
        }
      },
      {
        accessorKey: "mobile_no",
        header: "Number",
        enableSorting: false,
        accessorFn: (row) => row.country_code + row.mobile_no,
      },
      {
        accessorKey: "privacy_2",
        header: "Privacy Consent",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <span className={`badge ${row.privacy_2 ? "bg-success" : "bg-danger"}`}>
            {row.privacy_2 ? "Accepted" : "Rejected"}
          </span>
        ),
      },
      {
        accessorKey: "is_deleted",
        header: "Status",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Active", value: 2 },
          { text: "Deleted", value: 1 },
        ],
        Cell: ({ cell }) => <span className={`badge ${cell.getValue() === 0 ? "bg-success" : "bg-danger"}`}>{cell.getValue() === 0 ? "Active" : "Deleted"}</span>,
      },
      {
        accessorKey: "web2_wallet",
        header: "Wallet",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <a href={config.blockchainUrl + "/address/" + row.web2_wallet} target="_blank" rel="noopener noreferrer" style={{ color: "#3498db", textDecoration: "none" }}>
            {row.web2_wallet}
          </a>
        ),
      },
      {
        accessorKey: "city",
        header: "City",
        enableSorting: false,
        enableColumnFilter: false,
      },

      {
        accessorKey: "date_of_birth",
        header: "Date of Birth",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "date",
        accessorFn: (row) => moment(row.date_of_birth).format("DD-MM-YYYY"),
      },
      {
        accessorKey: "created_at",
        header: "First Login",
        enableSorting: false,
        filterVariant: "date",
        accessorFn: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm"),
      },
      {
        accessorKey: "fiscal_code",
        header: "Fiscal Code",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "kyc",
        header: "KYC Document",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <>
            {row.id_document_front && row.id_document_back && (
              <>
                <a href={config.imageUrl + row.id_document_front} target="_blank" className="dropdown-item" rel="noreferrer">
                  Document Front
                </a>
                <a className="dropdown-item" href={config.imageUrl + row.id_document_back} target="_blank" rel="noreferrer">
                  Document Back
                </a>
              </>
            )}
          </>
        ),
      },
      {
        accessorKey: "kyc_status",
        header: "KYC Action",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "UNVERIFIED", value: 4 },
          { text: "PENDING", value: 1 },
          { text: "APPROVED", value: 2 },
          { text: "REJECTED", value: 3 },
        ],
        accessorFn: (row) => (
          <>
            {!row.is_deleted && (
              <div className="d-flex flex-column">
                {row.kyc_status === 1 || row.kyc_status === 0 ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updatekycRequest(row);
                      }}
                      type="button"
                      className="btn btn-sm btn-primary mb-2"
                    >
                      Approve
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        rejectkycRequest(row);
                      }}
                      type="button"
                      className="btn btn-sm btn-primary"
                    >
                      Reject
                    </button>
                  </>
                ) : row.kyc_status === 2 ? (
                  <span style={{ color: "green" }}>Approved</span>
                ) : row.kyc_status === 3 ? (
                  <span style={{ color: "red" }}>Rejected</span>
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        ),
      },
      {
        accessorKey: "liquidity",
        header: "Liquidity",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <>
            {!row.is_deleted && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleLiquidityModal(row);
                }}
                type="button"
                className="btn btn-sm btn-primary"
              >
                Liquidity
              </button>
            )}
          </>
        ),
      },
      {
        accessorKey: "delete_action",
        header: "Delete Action",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <>
            {!row.is_deleted && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  deleteusers(row);
                }}
                type="button"
                className="btn btn-sm btn-primary"
              >
                Delete
              </button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    //    enableStickyHeader: true,
    getRowId: (row) => row.phoneNumber,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f", //add a border between columns
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f", //add a border between columns
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: (event) => {
        // Redirect to user details page
        window.location.href = `${config.baseUrl}userDetails?id=${row.original.id}`;
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleCsvDownload = () => {
    console.log("Downloading CSV");
    try {
      setCsvLoading(true);
      getAllUsers(JSON.stringify(columnFilters ?? [])).then((response) => {
        if (response.data.users.length > 0) {
          const csvData = [
            ["ID", "Email", "Mobile No", "First Name", "Last Name", "Country", "Status", "KYC Status", "Signup Date", "Privacy Consent","OS"],
            ...response.data.users.map((user) => [
              user.id,
              user.email,
              user.country_code + user.mobile_no,
              user.name,
              user.cognome,
              user.country,
              user.is_deleted === 0 ? "Active" : "Deleted",
              user.kyc_status === 1 ? "Pending" : user.kyc_status === 2 ? "Approved" : "Rejected",
              moment(user.created_at).format("DD-MM-YYYY HH:mm:ss"),
              user.privacy_2 ? "Accepted" : "Rejected",
              user.platform,
            ]),
          ];
          setCsvData(csvData);
          setCsvLoading(false);
        }
      });
    } catch (error) {
      setCsvLoading(false);
      setCsvData([]);
    }
  };

  const deleteusers = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await await userdeletetAction({ id: id });
        if (res.success) {
          const filtersToUse = JSON.stringify(table.getState().columnFilters ?? []);
          const response = await getUsersV2ListAction(table.getState().pagination.pageIndex, table.getState().pagination.pageSize, filtersToUse);
          setData(response.data.users);
          setRowCount(response.data.totalItems);
          // toast.success(res.msg);
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };

  const updatekycRequest = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Approve this Kyc Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updatekycapprovalAction(id);
        if (res.success) {
          Swal.fire("Approved!", res.msg, "success");
          const filtersToUse = JSON.stringify(table.getState().columnFilters ?? []);

          const response = await getUsersV2ListAction(table.getState().pagination.pageIndex, table.getState().pagination.pageSize, filtersToUse);
          setData(response.data.users);
          setRowCount(response.data.totalItems); // setTimeout(() => {
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const rejectkycRequest = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Reject this Kyc request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await rejectkycapprovalAction(id);
        if (res.success) {
          Swal.fire("Rejected!", res.msg, "success");
          setTimeout(async () => {
            const filtersToUse = JSON.stringify(table.getState().columnFilters ?? []);
            const response = await getUsersV2ListAction(table.getState().pagination.pageIndex, table.getState().pagination.pageSize, filtersToUse);
            setData(response.data.users);
            setRowCount(response.data.totalItems);
          }, 2000);
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  async function handleLiquidityModal(item) {
    getUsersLiquidity({ user_id: item.id })
      .then((res) => {
        if (res.success) {
          setItem({
            ...item,
            liquidity_amount: res.liquidity_amount,
            withdrawable_liquidity_amount: res.withdrawable_liquidity_amount,
          });
          setShowModal(true);
        } else {
          setItem(item);
          throw new Error("Something went while fetching liquidity");
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setShowModal(true);
      });
  }

  async function handleLiquiditySubmit(e) {
    e.preventDefault();

    if (lqiAmt === 0 || lqiAmt === null || lqiAmt === undefined || lqiAmt === "") {
      setvalidatioError({ ...validatioError, lqiAmt: true });
      return;
    }

    setvalidatioError({});
    setsaving(true);
    setUsersLiquidity({ user_id: item.id, bonus_liquidity: lqiAmt, is_bank_transfer: isTransfer })
      .then((res) => {
        if (res.success) {
          Swal.fire("Success", res.msg, "success");
        } else {
          Swal.fire("Failed", res.msg, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Failed", "Something went wrong", "error");
      })
      .finally(() => {
        setShowModal(false);
        setsaving(false);
        setIsTransfer(false);
      });
  }
  //getUsersV2ListAction
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            {/* <div id="loader"></div> */}
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Users </h4>
                          {csvLoading ? (
                            <div className="text-center">
                              <div className="spinner-border" role="status" aria-hidden="true"></div>
                            </div>
                          ) : csvData.length > 0 ? (
                            <CSVLink data={csvData} filename="Users.csv" className="btn btn-sm btn-primary">
                              Export CSV
                            </CSVLink>
                          ) : (
                            <>
                              <Button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                                Load CSV
                              </Button>
                            </>
                          )}
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Add Liquidity Modal */}
                  {showModal && (
                    <div className="modal fade show" id="liquidityModal" tabIndex={-1} aria-labelledby="liquidityModalLabel" aria-hidden="true" style={{ display: "block" }}>
                      <div className="modal-dialog">
                        <div className="modal-content">
                          {/* await items is not empty */}
                          {Object.keys(item).length > 0 ? (
                            <>
                              <div className="modal-header" data-bs-theme="dark">
                                <h5 className="modal-title" id="liquidityModalLabel">
                                  Liquidity for {item.name} {item.cognome}
                                </h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
                              </div>
                              {error ? (
                                <div className="modal-body">
                                  <div className="container">
                                    <div className="mb-3">
                                      <p className="text-center">Something went wrong. Please try again later</p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <form onSubmit={(e) => handleLiquiditySubmit(e)}>
                                  <div className="modal-body">
                                    <div className="container">
                                      <div className="mb-4">
                                        {/** Liquidity Details some p whit some data */}
                                        <p>
                                          <strong>Available Liquidity:</strong> {item.liquidity_amount}
                                        </p>
                                        <p>
                                          <strong>Withdrawable Liquidity:</strong> {item.withdrawable_liquidity_amount}
                                        </p>
                                      </div>

                                      <div className="mt-4 mb-3">
                                        <h5 className="text-center mt-5">Add Liquidity</h5>
                                        <label htmlFor="exampleFormControlInput1" className="form-label">
                                          Liquidity Amount
                                        </label>
                                        <input
                                          type="number"
                                          step=".01"
                                          className="form-control"
                                          id="exampleFormControlInput1"
                                          placeholder="Enter Liquidity Amount"
                                          onChange={(e) => setLqiAmt(e.target.value)}
                                          name="liquidity_amount"
                                        />
                                        {validatioError.lqiAmt && <span className="validationErr mt-1">Liquidity amount error</span>}
                                        <div className="form-check mt-3 p-0">
                                          <input
                                            className="form-check-input d-none"
                                            type="checkbox"
                                            id="liquidityTransferCheckbox"
                                            onChange={(e) => setIsTransfer(e.target.checked)}
                                          />
                                          <label className="form-check-label" htmlFor="liquidityTransferCheckbox">
                                            Is bank transfer?
                                          </label>
                                        </div>
                                      </div>

                                      <div className="modal-footer mt-20">
                                        {!saving ? (
                                          <button type="submit" className="btn btn-primary">
                                            Add Liquidity
                                          </button>
                                        ) : (
                                          <button type="submit" className="btn btn-primary" disabled>
                                            Please wait <i className="fa fa-spinner fa-spin"></i>
                                          </button>
                                        )}

                                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="modal-header" data-bs-theme="dark">
                                <h5 className="modal-title" id="liquidityModalLabel">
                                  Add Liquidity
                                </h5>
                                <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" onClick={() => setShowModal(false)} />
                              </div>
                              {/* on body fa-spin and close button */}
                              <div className="modal-body">
                                <div className="container">
                                  <div className="mb-3">
                                    <p className="text-center">Please select a user to add liquidity</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/*Add Liquidity Modal Ends */}
                </section>
                {/* /.content */}
                {/* /.content */}
              </div>
            </div>

            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};
export default UsersV2;
