import React, { useEffect, useState, useMemo, useCallback } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, Drawer, ThemeProvider, useMediaQuery } from "@mui/material";
import Swal from "sweetalert2";
import { useForm, Controller, getValues } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Select from 'react-select';
import config from "../coreFIles/config";
import { getItemListAction, getCashbackListAction, createCashbackAction, editCashbackAction, deleteCashbackAction, searchUsersByPhoneAction, getItemDetailAction } from "../Action/action";
import debounce from 'lodash/debounce';
import { components } from 'react-select';

const Cashback = () => {
  // Stati base
  const [data, setData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [itemList, setItemList] = useState([]);
  
  // Stati per errori e caricamento
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  
  // Stati per la paginazione e filtri
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Form handling
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
    defaultValues: {
      item_id: '',
      expiration_date: moment().format('YYYY-MM-DDTHH:mm'),
      discount_rules: [
        {
          quantity: '',
          discount_percent: ''
        }
      ],
      eligible_users: []
    }
  });

  // Aggiungi questo stato per tenere traccia del file caricato
  const [uploadedFile, setUploadedFile] = useState(null);

  // Aggiungi lo stato per tenere traccia dell'ID in modifica
  const [editingId, setEditingId] = useState(null);

  // Aggiungi questo stato per tenere traccia degli utenti caricati
  const [loadedUsers, setLoadedUsers] = useState([]);

  // Aggiungi questo stato
  const [userFilter, setUserFilter] = useState('all'); // 'all', 'used', 'unused'

  // Fetch dei dati
  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let filters = JSON.stringify(columnFilters ?? []);
      const response = await getCashbackListAction(
        pagination.pageIndex,
        pagination.pageSize,
        filters,
      );

      if (response.success) {
        setData(response.data.offers || []);
        setRowCount(response.data.totalItems || 0);
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      setData([]);
      setRowCount(0);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  // Funzione debounced per la ricerca
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        const filters = JSON.stringify([
          {
            id: "name",
            value: searchQuery
          }
        ]);
        getItems(filters);
      } else {
        getItems();
      }
    }, 500),
    []
  );

  const getItems = async (filters = "") => {
    const limit = filters ? 10000 : 20;
    try {
      let res = await getItemListAction(0, limit, filters);
      if (res.success) {
        let selectList = res.data.items.map((el) => ({
          value: el.id,
          label: el.name,
          image: config.imageUrl + el.image,
        }));
        setItemList(selectList);
      }
    } catch (error) {
      console.error("Error loading items:", error);
    }
  };

  // useEffect per il fetch dei dati del cashback
  useEffect(() => {
    fetchData();
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  // Definizione colonne
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: "item",
        header: "Item",
        Cell: ({ row }) => (
          <div className="d-flex align-items-center">
            <img 
              src={`${config.imageUrl}${row.original.item.image}`}
              alt={row.original.item.name}
              style={{ width: '30px', height: '30px', marginRight: '10px' }}
            />
            <span>{row.original.item.name}</span>
          </div>
        ),
        size: 200,
      },
      {
        accessorKey: "expiration_date",
        header: "Data Scadenza",       
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => moment.utc(cell.getValue()).format('DD/MM/YYYY HH:mm'),
        size: 150,
      },
      {
        accessorKey: "discount_rules",
        header: "Regole Sconto",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const rules = cell.getValue();
          return (
            <div>
              {rules.map((rule, index) => (
                <div key={index}>
                  {`${rule.quantity} shares - ${rule.discount_percent}%`}
                </div>
              ))}
            </div>
          );
        },
        size: 200,
      },
      {
        accessorKey: "OfferUsers",
        header: "Utenti Eligibili",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const users = cell.getValue();
          return <span>{users.length} utenti</span>;
        },
        size: 120,
      },
      {
        accessorKey: "createdAt",
        header: "Data Creazione",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => moment.utc(cell.getValue()).format('DD/MM/YYYY HH:mm'),
        size: 150,
      },
      {
        accessorKey: "actions",
        header: "Azioni",
        enableColumnFilter: false,
        enableSorting: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center">
            <button 
              className="btn btn-sm btn-primary me-2"
              onClick={() => handleEdit(row.original)}
            >
              Modifica
            </button>
            <button 
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(row.original.id)}
            >
              Elimina
            </button>
          </div>
        ),
        enableSorting: false,
        size: 150,
      },
    ],
    []
  );

  // Handlers
  const onSubmit = async (data) => {
    try {
      const response = editingId 
        ? await editCashbackAction(editingId, data)
        : await createCashbackAction(data);

      if (response.success) {
        Swal.fire(
          "Successo",
          editingId 
            ? "Automazione modificata con successo"
            : "Automazione creata con successo",
          "success"
        );
        setIsDrawerOpen(false);
        reset();
        fetchData();
      } else {
        Swal.fire(
          "Errore",
          "Operazione fallita. Riprova più tardi",
          "error"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        "Errore",
        "Si è verificato un errore. Riprova più tardi",
        "error"
      );
    }
  };

  const getSingleItem = async (itemId) => {
    try {
      const response = await getItemDetailAction({item_id:itemId});
      if (response.success) {
        return {
          value: response.data.id,
          label: response.data.name,
          image: config.imageUrl + response.data.image
        };
      }
    } catch (error) {
      console.error("Error loading item:", error);
    }
    return null;
  };

  const handleEdit = async (cashback) => {
    try {
      // Carica l'item specifico e la lista generale in parallelo
      const [itemData] = await Promise.all([
        getSingleItem(cashback.item.id),
        getItems()
      ]);

      // Se abbiamo trovato l'item, aggiungiamolo alla lista se non è già presente
      if (itemData) {
        setItemList(prev => {
          const exists = prev.some(item => item.value === itemData.value);
          return exists ? prev : [...prev, itemData];
        });
      }

      setEditingId(cashback.id);
      
      // Prepara la lista degli utenti esistenti escludendo l'admin
      const existingUsers = cashback.OfferUsers
        .filter(ou => !(ou.user.id === 1 && ou.user.email === 'admin@collecto.com'))
        .map(ou => ({
          id: parseInt(ou.user.id),
          phone: ou.user.mobile_no,
          name: ou.user.name || 'N/A',
          email: ou.user.email || 'N/A',
          has_used: ou.has_used
        }));
      
      setLoadedUsers(existingUsers);
      
      reset({
        item_id: cashback.item.id,
        expiration_date: moment.utc(cashback.expiration_date).format('YYYY-MM-DDTHH:mm'),
        discount_rules: cashback.discount_rules.length > 0 ? cashback.discount_rules : [{
          quantity: '',
          discount_percent: ''
        }],
        eligible_users: existingUsers.map(u => u.id)
      });

      setIsDrawerOpen(true);
    } catch (error) {
      console.error("Error in edit:", error);
      Swal.fire(
        "Errore",
        "Errore nel caricamento dei dati",
        "error"
      );
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Questa azione non può essere annullata!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, elimina!",
      cancelButtonText: "Annulla",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteCashbackAction(id);
          if (response.success) {
            Swal.fire("Eliminato!", "L'automazione è stata eliminata.", "success");
            fetchData(); // Aggiorna la lista
          } else {
            Swal.fire("Errore", response.message || "Errore durante l'eliminazione", "error");
          }
        } catch (error) {
          console.error("Error deleting cashback:", error);
          Swal.fire("Errore", "Errore durante l'eliminazione", "error");
        }
      }
    });
  };

  const handleCSVUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const text = e.target.result;
          const uniquePhoneNumbers = [...new Set(
            text.split('\n')
              .slice(1)
              .map(line => line.split(',')[0].trim())
              .filter(number => number)
              .map(number => number.startsWith('+') ? number : `+${number}`)
          )];

         

          const response = await searchUsersByPhoneAction(uniquePhoneNumbers);
          console.log('API Response:', response);

          if (response.success && Array.isArray(response.data)) {
            const users = response.data
              .filter(user => !(user.id === 1 && user.email === 'admin@collecto.com')) // Esclude l'admin
              .map(user => ({
                id: parseInt(user.id),
                phone: user.mobile_no,
                name: user.name || 'N/A',
                email: user.email || 'N/A'
              }));
            
            setLoadedUsers(users);
            setValue('eligible_users', users.map(u => u.id), { 
              shouldValidate: true,
              shouldDirty: true 
            });
          } else {
            console.error("Errore nella ricerca degli utenti o formato risposta non valido:", response);
          }
        } catch (error) {
          console.error("Error processing CSV:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  // Modifica la funzione handleRemoveFile
  const handleRemoveFile = () => {
    setUploadedFile(null);
   
    setLoadedUsers([]); // Pulisci la lista degli utenti
    setValue('eligible_users', []);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) fileInput.value = '';
  };

  // Aggiungi questa funzione per rimuovere un utente
  const removeUser = (userId) => {
    setLoadedUsers(prev => prev.filter(user => user.id !== userId));
    setValue('eligible_users', 
      getValues('eligible_users').filter(id => id !== userId)
    );
  };

  // Aggiungi questo theme
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  // Modifica la configurazione della tabella
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  // Aggiungi il componente Option personalizzato
  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.data.image && (
            <img 
              src={props.data.image} 
              alt={props.data.label}
              style={{ width: '30px', height: '30px', marginRight: '10px' }}
            />
          )}
          {children}
        </div>
      </components.Option>
    );
  };

  const handleNewAutomation = () => {
    setEditingId(null);
    setLoadedUsers([]); // Pulisci la lista degli utenti
    reset({
      item_id: '',
      expiration_date: moment().format('YYYY-MM-DDTHH:mm'),
      discount_rules: [{
        quantity: '',
        discount_percent: ''
      }],
      eligible_users: []
    });

    setUploadedFile(null); 
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) fileInput.value = '';
    setIsDrawerOpen(true);
  };

  // Aggiungi questa funzione per filtrare gli utenti
  const getFilteredUsers = () => {
    switch(userFilter) {
      case 'used':
        return loadedUsers.filter(user => user.has_used);
      case 'unused':
        return loadedUsers.filter(user => !user.has_used);
      default:
        return loadedUsers;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="wrapper">
        <Header />
        <Sidebar /> 
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h4 className="box-title">Cashback</h4>
                    <button
                      className="btn btn-primary float-right"
                      onClick={handleNewAutomation}
                    >
                      Nuova Automazione
                    </button>
                  </div>
                  <div className="box-body">
                    <ThemeProvider theme={tableTheme}>
                      <MaterialReactTable table={table} />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            reset();
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#1d2330",
              color: "#a2a4a9",
              width: isMobile ? "90%" : "50%",
            },
          }}
        >
          <div className="drawer-content p-4">
            <h5 className="drawer-title mb-4">Nuova Automazione Cashback</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-3">
                <label className="mb-2">Item</label>
                <Controller
                  name="item_id"
                  control={control}
                  rules={{ 
                    required: "Seleziona un item" 
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={itemList}
                      onMenuOpen={() => getItems()}
                      onInputChange={(value) => debouncedSearch(value)}
                      onChange={(selected) => field.onChange(selected ? selected.value : '')}
                      value={itemList.find(option => option.value === field.value)}
                      placeholder="Seleziona un item"
                      isClearable
                      components={{ Option: CustomOption }}
                    />
                  )}
                />
                {errors.item_id && (
                  <div className="text-danger">{errors.item_id.message}</div>
                )}
              </div>

              <div className="form-group mb-3">
                <label className="mb-2">Data di scadenza</label>
                <Controller
                  name="expiration_date"
                  control={control}
                  rules={{ 
                    required: "Data di scadenza richiesta",
                    validate: (value) => 
                      moment(value).isAfter(moment()) || 
                      "La data di scadenza non può essere nel passato"
                  }}
                  render={({ field }) => (
                    <input 
                      type="datetime-local" 
                      className="form-control"
                      {...field}
                      style={{ 
                        backgroundColor: "#131720",
                        color: "#a2a4a9",
                        border: "1px solid #ffffff1f"
                      }}
                    />
                  )}
                />
                {errors.expiration_date && (
                  <div className="text-danger">{errors.expiration_date.message}</div>
                )}
              </div>

              <div className="form-group mb-3">
                <label className="mb-2">Regole di sconto</label>
                <Controller
                  name="discount_rules"
                  control={control}
                  rules={{
                    validate: {
                      notEmpty: (rules) => 
                        rules.length > 0 || "Aggiungi almeno una regola di sconto",
                      validRules: (rules) => {
                        for (let rule of rules) {
                          if (!rule.quantity || rule.quantity < 1) 
                            return "La quantità deve essere maggiore di 0";
                          if (!rule.discount_percent || rule.discount_percent < 1 || rule.discount_percent > 100)
                            return "Lo sconto deve essere tra 1% e 100%";
                        }
                        return true;
                      },
                      orderedQuantities: (rules) => {
                        for (let i = 1; i < rules.length; i++) {
                          if (parseInt(rules[i].quantity) <= parseInt(rules[i-1].quantity)) {
                            return "Le quantità devono essere in ordine crescente";
                          }
                        }
                        return true;
                      }
                    }
                  }}
                  render={({ field }) => (
                    <>
                      {field.value.map((rule, index) => (
                        <div key={index} className="row mb-2">
                          <div className="col-5">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Quantità"
                              value={rule.quantity}
                              onChange={(e) => {
                                const newRules = [...field.value];
                                newRules[index].quantity = e.target.value;
                                field.onChange(newRules);
                              }}
                              style={{ 
                                backgroundColor: "#131720",
                                color: "#a2a4a9",
                                border: "1px solid #ffffff1f"
                              }}
                            />
                          </div>
                          <div className="col-5">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Percentuale"
                              value={rule.discount_percent}
                              onChange={(e) => {
                                const newRules = [...field.value];
                                newRules[index].discount_percent = e.target.value;
                                field.onChange(newRules);
                              }}
                              style={{ 
                                backgroundColor: "#131720",
                                color: "#a2a4a9",
                                border: "1px solid #ffffff1f"
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                const newRules = field.value.filter((_, i) => i !== index);
                                field.onChange(newRules);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          field.onChange([
                            ...field.value,
                            { quantity: '', discount_percent: '' }
                          ]);
                        }}
                      >
                        Aggiungi regola
                      </button>
                    </>
                  )}
                />
                {errors.discount_rules && (
                  <div className="text-danger mt-2">{errors.discount_rules.message}</div>
                )}
              </div>

              <div className="form-group mb-3">
                <label className="mb-2">Utenti Eligibili (CSV)</label>
                <Controller
                  name="eligible_users"
                  control={control}
                  rules={{
                    validate: (users) => 
                      users.length > 0 || "Seleziona almeno un utente eligibile"
                  }}
                  render={({ field }) => (
                    <>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="file"
                          accept=".csv"
                          onChange={handleCSVUpload}
                          className="form-control"
                          style={{ 
                            backgroundColor: "#131720",
                            color: "#a2a4a9",
                            border: "1px solid #ffffff1f"
                          }}
                        />
                        {uploadedFile && (
                          <button 
                            type="button" 
                            className="btn btn-danger"
                            onClick={handleRemoveFile}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div>
                      
                      {loadedUsers.length > 0 && (
                        <div 
                          className="mt-3"
                          style={{ 
                            maxHeight: '200px', 
                            overflowY: 'auto',
                            backgroundColor: "#131720",
                            border: "1px solid #ffffff1f",
                            borderRadius: '4px',
                            padding: '10px'
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <small>
                              Utenti: {getFilteredUsers().length} / {loadedUsers.length}
                            </small>
                            <select
                              value={userFilter}
                              onChange={(e) => setUserFilter(e.target.value)}
                              style={{ 
                                backgroundColor: "#131720",
                                color: "#a2a4a9",
                                border: "1px solid #ffffff1f",
                                width: 'auto',
                                minWidth: '150px'
                              }}
                            >
                              <option value="all">Tutti gli utenti</option>
                              <option value="used">Offerta utilizzata</option>
                              <option value="unused">Offerta non utilizzata</option>
                            </select>
                          </div>
                          {getFilteredUsers().map(user => (
                            <div 
                              key={user.id} 
                              className="d-flex justify-content-between align-items-center p-2 mb-1"
                              style={{ 
                                borderBottom: '1px solid #ffffff1f',
                                backgroundColor: "#1d2330"
                              }}
                            >
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                  <span className="text-white">{user.name}</span>
                                  {user.has_used && (
                                    <span 
                                      className="badge bg-success ms-2"
                                      style={{ fontSize: '0.7rem' }}
                                    >
                                      Offerta utilizzata
                                    </span>
                                  )}
                                </div>
                                <small className="text-muted d-block">Tel: {user.phone}</small>
                                <small className="text-muted d-block">Email: {user.email}</small>
                              </div>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger ms-2"
                                onClick={() => removeUser(user.id)}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                />
                {errors.eligible_users && (
                  <div className="text-danger mt-2">{errors.eligible_users.message}</div>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setIsDrawerOpen(false);
                    reset();
                  }}
                >
                  Chiudi
                </button>
                <button type="submit" className="btn btn-primary">
                  Salva
                </button>
              </div>
            </form>
          </div>
        </Drawer>

        <Footer />
      </div>
    </LocalizationProvider>
  );
};

export default Cashback; 