/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import { getWithdrawal_requestCountAction } from "../Action/action";

const Sidebar = () => {
  const [pageUrl] = useState(window.location.href);

  const [withdrawal_request_count, setWithdrawal_request_count] = useState(0);

  const [isWarehouseOpen, setIsWarehouseOpen] = useState(false);

  const logout = async () => {
    Cookies.remove("loginSuccessCollectoAdmin");
    window.location.href = config.baseUrl;
  };

  useEffect(() => {
    getWithdrawal_request();
  }, []);

  const getWithdrawal_request = async () => {
    let res = await getWithdrawal_requestCountAction();
    if (res.success) {
      setWithdrawal_request_count(res.data);
    }
  };

  return (
    <>
      <aside className="main-sidebar">
        {/* sidebar*/}
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll" style={{ height: "100%" }}>
              {/* sidebar menu*/}
              <ul className="sidebar-menu" data-widget="tree">
                <li className={pageUrl.match("/dashboard") ? "active" : ""}>
                  <a href={`${config.baseUrl}dashboard`}>
                    <i data-feather="home" />
                    <span>Dashboard</span>
                  </a>
                </li>
                <li className={pageUrl.match("/V2users") ? "active" : ""}>
                  <a href={`${config.baseUrl}V2users`}>
                    <i data-feather="users" />
                    <span>Users</span>
                  </a>
                </li>
                <li className={pageUrl.match("/V2transactions") ? "active" : ""}>
                  <a href={`${config.baseUrl}V2transactions`}>
                    <i data-feather="git-pull-request" />
                    <span>Transactions</span>
                  </a>
                </li>
                <li className={pageUrl.match("/userActions") || pageUrl.match("/userActions") ? "active" : ""}>
                  <a href={`${config.baseUrl}userActions`}>
                    <i data-feather="users" />
                    <span>user actions</span>
                  </a>
                </li>
                {/* <li
                    className={
                      pageUrl.match("/Category") ||
                      pageUrl.match("/Addcategory") ||
                      pageUrl.match("/Updatecategory")
                        ? "active"
                        : ""
                    }
                  >
                    <a href={`${config.baseUrl}Category`}>
                      <i data-feather="list" />
                      <span>Category</span>
                    </a>
                  </li> */}

                {/* <li
                  className={
                    pageUrl.match("/Category") ||
                    pageUrl.match("/Addcategory") ||
                    pageUrl.match("/Updatecategory") ||
                    pageUrl.match("/attributes") ||
                    pageUrl.match("/Addattributes") ||
                    pageUrl.match("/attributeupdate")
                      ? "treeview active"
                      : "treeview"
                  }
                >
                  <a href="/#">
                    <i data-feather="grid" />
                    <span>Category</span>
                    <i data-feather="arrow-down" style={{ float: "right" }} />
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <a href={`${config.baseUrl}Category`}>
                        <i className="ti-more" />
                        <span>Category</span>
                      </a>
                      <a href={`${config.baseUrl}attributes`}>
                        <i className="ti-more" />
                        <span>Attributes</span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className={pageUrl.match("/Category") || pageUrl.match("/Addcategory") || pageUrl.match("/Updatecategory") ? "active" : ""}>
                  <a href={`${config.baseUrl}Category`}>
                    <i data-feather="list" />
                    <span>Category</span>
                  </a>
                </li>

                <li className={pageUrl.match("/itemList") || pageUrl.match("/itemAdd") || pageUrl.match("/itemUpdate") ? "active" : ""}>
                  <a href={`${config.baseUrl}itemList`}>
                    <i data-feather="list" />
                    <span>Items</span>
                  </a>
                </li>
                <li className={pageUrl.match("/cashback") ? "active" : ""}>
                  <a href={`${config.baseUrl}cashback`}>
                    <i data-feather="percent" />
                    <span>Cashback</span>
                  </a>
                </li>
                <li
                  className={
                    pageUrl.match("/fornitori") ||
                    pageUrl.match("/magazzini")||
                    pageUrl.match("/pagamenti")
                      ? `treeview active ${isWarehouseOpen ? 'menu-open' : ''}`
                      : `treeview ${isWarehouseOpen ? 'menu-open' : ''}`
                  }
                >
                  <a 
                    href="#" 
                    onClick={(e) => {
                      e.preventDefault();
                      setIsWarehouseOpen(!isWarehouseOpen);
                    }}
                  >
                    <i data-feather="database" />
                    <span>Gestione magazzino</span>
                    <i data-feather="arrow-down" style={{ float: "right" }} />
                  </a>
                  <ul className="treeview-menu" style={{ display: isWarehouseOpen ? 'block' : 'none' }}>
                    <li>
                      <a href={`${config.baseUrl}fornitori`}>
                        <i className="ti-more" />
                        <span>Fornitori</span>
                      </a>
                      <a href={`${config.baseUrl}magazzini`}>
                        <i className="ti-more" />
                        <span>Magazzini</span>
                      </a>
                      <a href={`${config.baseUrl}pagamenti`}>
                        <i className="ti-more" />
                        <span>Pagamenti</span>
                      </a>
                    </li>
                  </ul>
                </li>

                <li className={pageUrl.match("/alert_request") ? "active" : ""}>
                  <a href={`${config.baseUrl}alert_request`}>
                    <i data-feather="alert-circle" />
                    <span>Alert Request</span>
                  </a>
                </li>
                <li className={pageUrl.match("/notification") ? "active" : ""}>
                  <a href={`${config.baseUrl}notification`}>
                    <i data-feather="bell" />
                    <span>Notification</span>
                  </a>
                </li>
                <li className={pageUrl.match("/promo") ? "active" : ""}>
                  <a href={`${config.baseUrl}promo`}>
                    <i data-feather="percent" />
                    <span>Promo modal</span>
                  </a>
                </li>
                <li className={pageUrl.match("/userguide") ? "active" : ""}>
                  <a href={`${config.baseUrl}userguide`}>
                    <i data-feather="book" />
                    <span>User Guide</span>
                  </a>
                </li>
                <li className={pageUrl.match("/expertdetails") ? "active" : ""}>
                  <a href={`${config.baseUrl}expertdetails`}>
                    <i data-feather="user-check" />
                    <span>Expert Details</span>
                  </a>
                </li>

                <li className={pageUrl.match("/giftCards") ? "active" : ""}>
                  <a href={`${config.baseUrl}giftCards`}>
                    <i data-feather="gift" />
                    <span>Gift Cards</span>
                  </a>
                </li>
                <li className={pageUrl.match("/giftItems") ? "active" : ""}>
                  <a href={`${config.baseUrl}giftItems`}>
                    <i data-feather="gift" />
                    <span>Gift Items</span>
                  </a>
                </li>
                <li className={pageUrl.match("/withdraw") ? "active" : ""}>
                  <a href={`${config.baseUrl}withdraw`}>
                    <i data-feather="git-pull-request" />
                    <span>
                      Withdrawals
                      {withdrawal_request_count > 0 ? " (" + withdrawal_request_count + ")" : ""}
                    </span>
                  </a>
                </li>
                <li className={pageUrl.match("/refunds") ? "active" : ""}>
                  <a href={`${config.baseUrl}refunds`}>
                    <i data-feather="git-pull-request" />
                    <span>Refunds</span>
                  </a>
                </li>
                <li className={pageUrl.match("/referrals") ? "active" : ""}>
                  <a href={`${config.baseUrl}referrals`}>
                    <i data-feather="users" />
                    <span>Referrals</span>
                  </a>
                </li>

                <li className={pageUrl.match("/changepassword") ? "active" : ""}>
                  <a href={`${config.baseUrl}changepassword`}>
                    <i data-feather="lock" />
                    <span>Change Password</span>
                  </a>
                </li>
                
                <li className="">
                  <a href="#;" onClick={logout}>
                    <i data-feather="log-out" />
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </aside>
    </>
  );
};
export default Sidebar;
