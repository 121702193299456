import { Box, Grid, TextField, Typography } from "@mui/material";
import config from "../coreFIles/config";
import moment from "moment";

const UserDetails = ({ userDetails }) => {
  return (
    <div>
      <h3>User Details</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          {userDetails.user.signup_by === "GMAIL" || userDetails.user.signup_by === "FACEBOOK" || userDetails.user.signup_by === "APPLE" ? (
            parseInt(userDetails.user.profile_change_stt) === 1 ? (
              userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
                <img
                  src="./images/avatar-2.png"
                  className="bg-primary-light "
                  alt=""
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <a target="__blank" href={`${config.imageUrl}${userDetails.user.profile_pic}`}>
                  <img
                    src={`${config.imageUrl}${userDetails.user.profile_pic}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                    }}
                    alt=""
                  />
                </a>
              )
            ) : userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
              <img
                src="./images/avatar-2.png"
                className="bg-primary-light "
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
              />
            ) : (
              <a target="__blank" href={userDetails.user.profile_pic}>
                <img
                  src={userDetails.user.profile_pic}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                  alt=""
                />
              </a>
            )
          ) : userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
            <img src="./images/avatar-2.png" className="bg-primary-light " alt="" style={{ width: "100px", height: "100px", borderRadius: "50px" }} />
          ) : (
            <a target="__blank" href={`${config.imageUrl}${userDetails.user.profile_pic}`}>
              <img
                src={`${config.imageUrl}${userDetails.user.profile_pic}`}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
                alt=""
              />
            </a>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, p: 2, borderRadius: 2, border: '1px solid #5a5f66' }}>
            <Box>
              <Typography variant="h6" gutterBottom>User Liquidity</Typography>
              <Typography variant="body1">
                Total: {Number(userDetails.user.liquidity_amount + userDetails.user.primary_bonus_liquidity).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                Withdrawable: {Number(userDetails.user.withdrawable_liquidity_amount).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                Bank Transfer Liquidity: {Number(userDetails.user.bank_transfer_liquidity).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                Bonus Liquidity: {Number(userDetails.user.bonus_liquidity).toFixed(2)}
              </Typography>
              <Typography variant="body1">
                Primary Bonus Liquidity: {Number(userDetails.user.primary_bonus_liquidity).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="ID"
            value={userDetails.user.id}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            value={userDetails.user.name}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            value={userDetails.user.email}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Country Code"
            value={userDetails.user.country_code}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Mobile No"
            value={userDetails.user.mobile_no}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="User Role"
            value={userDetails.user.user_role === 1 ? "Admin" : "User"}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="KYC Status"
            value={
              userDetails.user.kyc_status === 1
                ? "Pending"
                : userDetails.user.kyc_status === 0
                ? "Unverified"
                : userDetails.user.kyc_status === 2
                ? "Approved"
                : userDetails.user.kyc_status === 3
                ? "Rejected"
                : "Unknown"
            }
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Surname"
            value={userDetails.user.surname}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Gender"
            value={userDetails.user.gender}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Country"
            value={userDetails.user.country}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            value={userDetails.user.city}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Date of Birth"
            value={userDetails.user.date_of_birth}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Fiscal Code"
            value={userDetails.user.fiscal_code}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Mobile Verified"
            value={userDetails.user.is_mobile_verify ? "Yes" : "No"}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email Verified"
            value={userDetails.user.is_email_verify ? "Yes" : "No"}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Created At"
            value={moment(userDetails.user.created_at).format("YYYY-MM-DD HH:mm:ss")}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Updated At"
            value={moment(userDetails.user.updated_at).format("YYYY-MM-DD HH:mm:ss")}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Signup By"
            value={userDetails.user.signup_by}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Web2 Wallet"
            value={userDetails.user.web2_wallet || "N/A"}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Referral Code"
            value={userDetails.user.referral_code || "N/A"}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            margin="normal"
          />
        </Grid>
        {userDetails.user.id_document_front && userDetails.user.id_document_back && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              User Documents
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <a href={config.imageUrl + userDetails.user.id_document_front} target="_blank" rel="noreferrer">
                  <img src={config.imageUrl + userDetails.user.id_document_front} alt="Document Front" style={{ maxWidth: "100%", height: "auto" }} />
                </a>
                <Typography variant="subtitle1" align="center">
                  Document Front
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <a href={config.imageUrl + userDetails.user.id_document_back} target="_blank" rel="noreferrer">
                  <img src={config.imageUrl + userDetails.user.id_document_back} alt="Document Back" style={{ maxWidth: "100%", height: "auto" }} />
                </a>
                <Typography variant="subtitle1" align="center">
                  Document Back
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UserDetails;
