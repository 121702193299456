import React, { useEffect, useState, useCallback } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Swal from "sweetalert2";
import { getModalPromo, deleteModalPromo, addModalPromo, getItemListAction, editModalPromo } from "../Action/action";
import moment from "moment/moment";
import Select from "react-select";
import debounce from 'lodash/debounce';

const PromoModal = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [form, setForm] = useState({
    previewImage: "./images/noimage.png",
    title: "",
    title_en: "",
    image: "",
    item: "",
    description: "",
    description_en: "",
    button_text: "",
    button_text_en: "",
    start_date: "",
  });
  const [promo, setPromo] = useState({
    previewImage: "./images/noimage.png",
    title: "",
    title_en: "",
    image: "",
    item: "",
    description: "",
    description_en: "",
    button_text: "",
    button_text_en: "",
    start_date: "",
  });

  const [validationError, setValidationError] = useState({});
  const [saving, setSaving] = useState(false);
  const [remove, setRemove] = useState(false);
  const [itemList, setItemList] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    getItemDetail();
  }, []);

  const toggleEditMode = () => setIsDisabled(!isDisabled);

  const getItemDetail = async () => {
    let res = await getModalPromo();
    if (res.success) {
      setIsDisabled(true);
      let data = res.data[0];
      let start_date = moment(data.start_date).format("YYYY-MM-DD");
      setForm((old) => {
        return {
          ...old,
          title: data.title,
          image: data.media,
          previewImage: config.imageUrl + data.media,
          description: data.description,
          button_text: data.button_text,
          item: data.item_id,
          start_date: start_date,
          title_en: data.title_en,
          description_en: data.description_en,
          button_text_en: data.button_text_en,
        };
      });
      setPromo((old) => {
        return {
          ...old,
          title: data.title,
          image: data.media,
          previewImage: config.imageUrl + data.media,
          description: data.description,
          button_text: data.button_text,
          item: data.item_id,
          start_date: start_date,
          title_en: data.title_en,
          description_en: data.description_en,
          button_text_en: data.button_text_en,
        };
      });
      getItems();
    } else {
      getItems();
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery) {
        const filters = JSON.stringify([
          {
            id: "name",
            value: searchQuery
          }
        ]);
        getItems(filters);
      } else {
        getItems();
      }
    }, 500),
    []
  );

  const getItems = async (filters = "") => {
    const limit = filters ? 10000 : 20;
    
    let res = await getItemListAction(0, limit, filters);
    if (res.success) {
      let selectList = res.data.items.map((el) => ({
        value: el.id,
        label: el.name,
        image: config.imageUrl + el.image,
        target: {
          name: "item",
          value: el.id,
        },
      }));
      setItemList(selectList);
    }
  };

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, image: img, previewImage: URL.createObjectURL(img) };
      });
    }
  };

  function validate() {
    let imageError = "";
    let titleError = "";
    let descriptionError = "";
    let textButtonError = "";
    let startDateError = "";
    if (form.title === "") {
      titleError = "Il titolo è obbligatorio.";
    }
    if (form.image === "") {
      imageError = "L'immagine è obbligatoria.";
    }
    if (form.description === "") {
      descriptionError = "La descrizione è obbligatoria.";
    }
    if (form.button_text === "") {
      textButtonError = "Il testo del pulsante è obbligatorio.";
    }
    if (form.start_date === "") {
      startDateError = "La data di inizio è obbligatoria.";
    }
    if (form.title_en === "") {
      titleError = "Titolo (EN) è obbligatorio.";
    }
    if (form.description_en === "") {
      descriptionError = "Descrizione (EN) è obbligatoria.";
    }
    if (form.button_text_en === "") {
      textButtonError = "Testo del pulsante (EN) è obbligatorio.";
    }

    if (titleError || imageError || descriptionError || textButtonError || startDateError) {
      setValidationError({
        titleError,
        imageError,
        descriptionError,
        textButtonError,
        startDateError,
      });
      return false;
    } else {
      setValidationError({
        titleError,
        imageError,
        descriptionError,
        textButtonError,
        startDateError,
      });
      return true;
    }
  }

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setSaving(true);
    try {
      let formData = new FormData();
      formData.append("title", form["title"]);
      formData.append("description", form["description"]);
      formData.append("button_text", form["button_text"]);
      formData.append("start_date", form["start_date"]);
      formData.append("media", form.image);
      formData.append("item_id", form.item);
      formData.append("title_en", form.title_en);
      formData.append("description_en", form.description_en);
      formData.append("button_text_en", form.button_text_en);
      let res = await addModalPromo(formData);
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setSaving(false);
        setTimeout(() => {
          getItemDetail();
        }, 1200);
      } else {
        closeProcessingAlert();
        setSaving(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transazione rifiutata!");
      } else {
        toast.error("Errore di transazione: " + err.message);
      }
      setSaving(false);
      return false;
    }
  };

  const deletePromo = async () => {
    setRemove(true);
    try {
      let res = await deleteModalPromo();
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setTimeout(() => {
          setForm((old) => {
            return {
              ...old,
              title: "",
              previewImage: "./images/noimage.png",
              image: "",
              item: "",
              description: "",
              button_text: "",
              start_date: "",
              title_en: "",
              description_en: "",
              button_text_en: "",
            };
          });
          setPromo((old) => {
            return {
              ...old,
              title: "",
              previewImage: "./images/noimage.png",
              image: "",
              item: "",
              description: "",
              button_text: "",
              start_date: "",
              title_en: "",
              description_en: "",
              button_text_en: "",
            };
          });
          setItem((old) => {
            return {
              ...old,
              title: "",
            };
          });
          setRemove(false);
        }, 1200);
      } else {
        closeProcessingAlert();
        setRemove(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transazione rifiutata!");
      } else {
        toast.error("Errore di transazione: " + err.message);
      }
      setRemove(false);
      return false;
    }
  };

  const editPromo = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setSaving(true);
    try {
      let formData = new FormData();
      formData.append("title", form.title);
      formData.append("description", form.description);
      formData.append("button_text", form.button_text);
      formData.append("start_date", form.start_date);
      formData.append("media", form.image);
      formData.append("item_id", form.item);
      formData.append("title_en", form.title_en);
      formData.append("description_en", form.description_en);
      formData.append("button_text_en", form.button_text_en);
      let res = await editModalPromo(formData);
      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setSaving(false);
        setTimeout(() => {
          getItemDetail();
        }, 1200);
      } else {
        closeProcessingAlert();
        setSaving(false);
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transazione rifiutata!");
      } else {
        toast.error("Errore di transazione: " + err.message);
      }
      setSaving(false);
      return false;
    }
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Aggiungi Promozione</h4>
                    </div>
                    <div className="col mt-20 mb-50">
                      <div className="row m-0">
                        <div className="col-md-2" />   
                        <div className="col-md-8" role="alert">
                          <h5>Variabili Dinamiche</h5>
                          <p>Puoi utilizzare le seguenti variabili nel titolo e nella descrizione per rendere il testo dinamico:</p>
                          <ul>
                            <li><strong>{"{{available_shares}}"}</strong> - Numero di azioni disponibili</li>
                            <li><strong>{"{{sold_percentage}}"}</strong> - Percentuale di azioni vendute</li>
                            <li><strong>{"{{sold_shares}}"}</strong> - Numero di azioni vendute</li>
                            <li><strong>{"{{time_elapsed}}"}</strong> - Tempo trascorso dal primo acquisto</li>
                            <li><strong>{"{{log_time}}"}</strong> - Tempo trascorso dal primo acquisto (logaritmico)</li>
                            <li><strong>{"{{total_sold}}"}</strong> - Totale quote vendute</li>

                          </ul>
                        </div>
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Titolo</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="title"
                                value={form.title}
                                onChange={inputHandler}
                                placeholder="Titolo"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.titleError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Descrizione</label>
                            <div className="col-md-12">
                              <textarea
                                className="form-control"
                                rows="3"
                                autoComplete="off"
                                name="description"
                                value={form.description}
                                onChange={inputHandler}
                                placeholder="Descrizione"
                                disabled={isDisabled}
                                style={{ resize: "vertical", minHeight: "80px" }}
                              ></textarea>
                            </div>
                            <span className="validationErr">{validationError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Titolo (EN)</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="title_en"
                                value={form.title_en}
                                onChange={inputHandler}
                                placeholder="Titolo (EN)"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.titleError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Descrizione (EN)</label>
                            <div className="col-md-12">
                              <textarea
                                className="form-control"
                                rows="3"
                                autoComplete="off"
                                name="description_en"
                                value={form.description_en}
                                onChange={inputHandler}
                                placeholder="Descrizione (EN)"
                                disabled={isDisabled}
                                style={{ resize: "vertical", minHeight: "80px" }}
                              ></textarea>
                            </div>
                            <span className="validationErr">{validationError.descriptionError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Testo del pulsante</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="button_text"
                                value={form.button_text}
                                onChange={inputHandler}
                                placeholder="Testo del pulsante"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.textButtonError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Testo del pulsante</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                name="button_text_en"
                                value={form.button_text_en}
                                onChange={inputHandler}
                                placeholder="Testo del pulsante (EN)"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.textButtonError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />

                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Data di inizio</label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="date"
                                autoComplete="off"
                                name="start_date"
                                value={form.start_date}
                                onChange={inputHandler}
                                placeholder="Data di inizio"
                                disabled={isDisabled}
                              />
                            </div>
                            <span className="validationErr">{validationError.startDateError}</span>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>
                      <div className="row m-0 p-0">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Immagine</label>
                            <div className="col-md-12">
                              {(!isDisabled || promo.button_text === "") && <input className="form-control mb-2" type="file" name="image" onChange={onImageChange} />}
                              <img alt="" src={form.previewImage} width="100px" height="100px"></img>
                            </div>
                            <span className="validationErr">{validationError.imageError}</span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">Item</label>
                            {!isDisabled || promo.button_text === "" ? (
                              <Select
                                name="item"
                                options={itemList}
                                formatOptionLabel={(item) => (
                                  <div>
                                    <img width="20px" height="20px" src={item.image} alt="" />
                                    <span>{item.label}</span>
                                  </div>
                                )}
                                onChange={inputHandler}
                                onInputChange={(value) => {
                                  debouncedSearch(value);
                                }}
                                filterOption={null}
                                isSearchable={true}
                                styles={{ 
                                  container: (base) => ({ 
                                    ...base, 
                                    marginBottom: "20px" 
                                  }) 
                                }}
                              />
                            ) : null}
                            <div className="col-md-12">
                              {promo.button_text !== "" && promo.item !== "" ? (
                                <div>
                                  <img width="60px" height="60px" src={item.image} alt="" />
                                  <span className="m-2">{item.label}</span>
                                </div>
                              ) : (
                                <span>Nessun item selezionato</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      <div className="text-center">
                        <br />
                        {!remove && promo.button_text !== "" ? (
                          <button className="btn btn-primary mx-2 w-100" onClick={deletePromo}>
                            Elimina
                          </button>
                        ) : remove ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" disabled>
                            Attendere prego <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : null}
                        {!saving && promo.button_text === "" ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={submitForm}>
                            Salva
                          </button>
                        ) : saving ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" disabled>
                            Attendere prego <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        ) : !isDisabled ? (
                          <button type="submit" className="btn btn-primary mx-2 w-150" onClick={editPromo}>
                            Salva nuovo modal
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={toggleEditMode}>
                            Modifica
                          </button>
                        )}
                        {!isDisabled ? (
                          <button type="submit" className="btn btn-primary mx-2 w-100" onClick={toggleEditMode}>
                            Annulla
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default PromoModal;
